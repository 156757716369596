@import '@invitae/ids-sass/sass/colors.scss';

.announcementContainer {
  color: $white;
  background-color: $cobalt;
  padding-block: 30px;
  padding-inline: 0;
  text-align: center;

  .quizLink {
    color: $bermuda;
  }
}
