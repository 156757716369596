@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';

.root {
  display: flex;
  justify-content: center;

  @include screen-medium {
    margin-block-start: 55px;
  }
}

.innerWrapper {
  inline-size: 100%;
  max-inline-size: 990px;
}

.heading {
  margin-block-start: 20px;
  margin-block-end: 8px;
}

.filtersWrapper {
  flex-direction: column;

  @include screen-large {
    flex-direction: row;
    margin-block-start: 0;
  }
  display: flex;
  justify-content: space-between;
}

.select {
  inline-size: 100%;
  margin-block-start: 40px;

  @include screen-large {
    inline-size: 48%;
  }
}

.jobsWrapper {
  margin-block: 60px;
  margin-inline: 0;
}
