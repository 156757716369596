@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.grid {
  display: grid;
}

.container {
  padding: 40px;
  margin-block: 20px;
  margin-inline: 0;
  border: 1px solid $gray3;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 70px;
  grid-template-areas:
    'fc-about fc-about fc-about fc-about fc-about fc-about'
    'fc-header fc-header fc-header fc-header fc-header fc-header'
    'fc-fields fc-fields fc-fields fc-result fc-result fc-result'
    'fc-disclaimer fc-disclaimer fc-disclaimer fc-disclaimer fc-disclaimer fc-disclaimer';

  @include screen-small() {
    margin-block: 20px;
    margin-inline: 20px;
    padding: 32px;
    grid-template-columns: 1fr;
    grid-column: span 12;
    grid-template-areas:
      'fc-about'
      'fc-header'
      'fc-fields'
      'fc-result'
      'fc-disclaimer';
  }

  @include screen-medium() {
    margin-block: 20px;
    grid-column: span 12;
  }

  @include screen-large() {
    grid-column: 1 / 9;
  }

  @include screen-extra-large() {
    grid-column: 1 / 8;
  }
}

.aboutContainer {
  margin-block-start: 24px;
  margin-block-end: 32px;
  grid-column: span 12;
}

.aboutHeading {
  margin-block-end: 16px;
}

.header {
  grid-area: fc-header;
}

.fields {
  grid-area: fc-fields;

  > div {
    margin-block-start: 40px;
  }

  > button {
    margin-block: 40px;
    inline-size: 100%;
  }
}

.inputPrefix {
  margin: 0;
}

.result {
  margin-block: 40px;
  grid-area: fc-result;
  padding: 30px;
  background: $catskill;
  block-size: fit-content;

  > p:not(:last-child) {
    margin-block-end: 10px;
  }

  @include screen-small() {
    margin-block-start: 0;
    margin-block-end: 40px;
  }
}

.disclaimer {
  grid-area: fc-disclaimer;
}

.inputError {
  margin-block-start: 3px;
  color: $error;
}
