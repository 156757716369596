@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/layers.scss';
@import '@invitae/ids-sass/sass/polyfills.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

// lage text item always in hero.
.heading {
  text-align: center;
  font-size: 35px;
  font-weight: $font-weight-demibold;
  line-height: 1.07;
  letter-spacing: 0.4px;
  color: $white;

  @include screen-medium {
    inline-size: 350px;
    font-size: 45px;
    text-align: inherit;
  }

  @include screen-large {
    font-size: 85px;
    inline-size: 650px;
  }
}

// sub text item always in hero but hidden on mobile.
.subHeading {
  display: none;
  margin-block: 15px 0;
  margin-inline: 0;
  font-size: 15px;
  font-weight: $font-weight-demibold;
  color: $white;
  inline-size: 350px;
  margin-block-start: 20px;

  @include screen-medium {
    display: block;
  }

  @include screen-large {
    font-size: 20px;
    inline-size: 600px;
  }
}

// holds the h1 and h2 blocks in the hero
.copyContainer {
  margin-block-start: 10px;
  flex-direction: column;
  z-index: $layerPriority;
  position: relative;

  @include screen-medium {
    padding-block-end: 30px;
  }

  @include screen-large {
    padding-block-end: 60px;
    margin-block-start: 80px;
  }
}

// the outermost div of the hero section
.container {
  display: flex;
  min-block-size: 155px;
  background-color: $cobalt;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-block-end: 15px;
  padding-block-start: 15px;

  @include screen-medium {
    min-block-size: 250px;
  }

  @include screen-large {
    min-block-size: 500px;
    padding-block-start: 25px;
  }
}

.navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  margin-block: 0;
  margin-inline: 10px;

  @include screen-medium {
    inline-size: 65vw;
    align-self: center;
  }
}

.pagination {
  position: absolute;
  inset-block-end: 10px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  block-size: 2px;
  flex: 1;

  div {
    background-color: $bermuda;
    block-size: 2px;
    inline-size: 20px;
    opacity: 0.5;
    margin-block: 0;
    margin-inline: 5px;
    border-radius: 2px;

    @include screen-medium {
      inline-size: 25px;
    }

    @include screen-large {
      block-size: 4px;
      inline-size: 50px;
    }
  }

  .active {
    opacity: 1;
  }

  @include screen-large {
    inset-block-end: 30px;
  }

  @include ie-only {
    transform: translateX(-50%);
  }
}

.blob {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  inline-size: 120px;
  block-size: 55px;
  background-image: url('https://invitae.imgix.net/graphics/blob-bermuda_2.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom -1px;

  @include screen-medium {
    inline-size: 195px;
    block-size: 115px;
  }

  @include screen-large {
    inline-size: 415px;
    block-size: 240px;
    max-inline-size: 32vw;
  }
}
