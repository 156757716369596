@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.body {
  flex: 1;
}

.filtersWrapper {
  // left in as reminder
  color: $mulberry;
  flex-direction: column;

  @include screen-large {
    flex-direction: row;
    margin-block-start: 0;
  }
  display: flex;
  justify-content: space-between;
}

//  h2 when flexed out into the body or p tags.
.bodyCopy {
  @include screen-medium {
    font-weight: $font-weight-demibold;
    line-height: 1.3;
  }

  @include screen-large {
    font-size: 32px;
  }
}

.bodyCopy + .bodyCopy {
  margin-block-start: 15px;

  @include screen-medium {
    margin-block-start: 35px;
  }

  @include screen-large {
    margin-block-start: 80px;
  }
}
