@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/polyfills.scss';
@import '@invitae/ids-sass/sass/colors.scss';

.titleAndButtonTopRow {
  display: flex;

  @include screen-medium {
    flex-direction: row;
    justify-content: space-between;
  }

  @include screen-small {
    flex-direction: column;
  }
}

.postersAndPresentationItem {
  margin-block-start: 40px;
}

.postersAndPresentationHeader {
  color: $ocean;
  margin-block-start: 60px;
  margin-block-end: 10px;
}

.posterAndPresentationSubheader {
  color: $mulberry;
  margin-block-end: 14px;
}

.borderTop {
  padding-block-start: 30px;
  border-block-start: 1px $gray5 solid;
}

.posterAndPresentationButton {
  align-self: start;
  color: $white;

  @include ie-only {
    block-size: 50px;
  }
}

.sideButton {
  margin-inline-start: 40px;

  @include screen-small {
    margin-inline-start: 0;
    margin-block-start: 20px;
  }
}

.errorMessage {
  margin-block-start: 60px;
  color: $ocean;
}
