@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';

// TODO: remove this once CEEJ-1352 has been resolved
// https://invitae.jira.com/browse/CEEJ-1352
.root :global(.nv-input--input-wrapper) {
  background-color: $white;
}

.root {
  padding-block: 50px;

  @include screen-medium {
    padding-block: 100px;
  }
}

.heading {
  text-align: center;
}

.body {
  text-align: center;
  margin-block: 20px;
}

// single column for mobile, two column for desktop
.left,
.right {
  grid-column: 1 / span 4;
}

@include screen-medium {
  .left {
    grid-column: 1 / span 6;
  }

  .right {
    grid-column: 7 / span 6;
  }
}

// vertical spacing between form fields
.left > div,
.right > div {
  margin-block-start: 20px;
}

.actions {
  margin-block-start: 30px;
  text-align: center;
}

// themes for background colors
.gray {
  background-color: $gray7;
}

.white {
  background-color: $white;
}
