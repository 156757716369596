@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';

.root {
  padding: 55px 0 0;

  @include screen-medium {
    padding: 0;
  }
}

.textSection {
  grid-column: 4 span;
  align-self: center;
}

.mediaSection {
  grid-column: 8 span;
  background: url('../../../assets/images/iphone-shape-background.jpeg') no-repeat;
  background-position: center;
  background-size: contain;
  block-size: 370px;

  @include screen-small {
    grid-column: 4 span;
    grid-row: 2;
  }

  @include screen-medium {
    block-size: 500px;
    background-size: 85%;
    background-position: calc(50% - 30px);
  }
}

.subHeading {
  margin-block-start: 20px;

  &:global(.nv-subheading-1-book) {
    font-size: 16px;
    line-height: 22px;
  }
}

.mobilePhoneSection {
  position: relative;
  background: url('../../../assets/images/iphone.png') no-repeat;
  background-size: contain;
  background-position: center;
  pointer-events: none;
  block-size: 100%;
  transform: scale(0.71);

  @include screen-small {
    transform: scale(0.9);
  }
}

.mobilePhoneVideoContainer {
  border-radius: 20px;
  overflow: hidden;
  inline-size: 140px;
  margin: auto;
  position: relative;
  inset-inline-start: 22px;
  inset-block-start: 24px;
  block-size: 80%;

  > div {
    margin: auto;
  }

  @include screen-medium {
    inline-size: 186px;
    inset-inline-start: 30px;
    inset-block-start: 34px;
  }
}

.button {
  margin-block-start: 30px;
}
