@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.root {
  padding-block: 70px;
  padding-inline: 70px;
  background-color: $gray7;

  .termGrid {
    @include screen-small {
      display: block;
    }

    .term {
      grid-row: 1;
      grid-column: span 4;
      font-weight: $font-weight-demibold;
      font-size: 28px;
      line-height: 34px;

      @include screen-large {
        grid-column: span 5;
        font-size: 48px;
        line-height: 62px;
      }

      span {
        display: block;

        &:last-child {
          margin-block-start: 10px;
        }
      }
    }

    .termDefinition {
      grid-row: 1;
      grid-column: span 8;
      display: flex;
      align-items: center;

      @include screen-small {
        margin-block-start: 15px;
      }

      @include screen-large {
        grid-column: span 6;
      }
    }

    .termSubHeading {
      grid-row: 2;
      grid-column: 1 / 13;
      margin-block: 20px;
      margin-inline: 0;

      @include screen-large {
        margin-block-start: 70px;
      }
    }

    .contactOption1,
    .contactOption2 {
      margin-block-start: 20px;
      padding-block: 30px 35px;
      padding-inline: 30px;
      background-color: #ffffff;
      box-shadow: 0 0 20px #d3d3d3;
      border-radius: 8px;

      @include screen-small {
        padding-block: 30px;
        padding-inline: 30px 20px;
      }

      @include screen-large {
        padding-block: 40px;
        padding-inline: 55px 30px;
      }

      p {
        margin-block-start: 20px;

        @include screen-large {
          margin-block-start: 10px;
        }
      }
    }

    .contactOption1 {
      grid-row: 3;
      grid-column: 1 / 13;

      @include screen-large {
        grid-row: 3;
        grid-column: 1 / 7;
      }
    }

    .contactOption2 {
      grid-row: 4;
      grid-column: 1 / 13;

      @include screen-large {
        grid-row: 3;
        grid-column: 7 / 13;
      }
    }
  }
}
