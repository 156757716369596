@import '@invitae/ids-sass/sass/colors.scss';

.errorMessage {
  color: $error;
  font-size: 12px;
}

.captchaWrapperError {
  inline-size: 304px;
  border: 1px solid red;
}
