@import '@invitae/ids-sass/sass/colors.scss';

.item {
  padding-block-end: 20px;

  &:not(:last-child) {
    margin-block-end: 20px;
    border-block-end: 1px solid $gray4;
  }

  .highlight {
    display: inline-block;
    background: $buttermilk;
    border-radius: 2px;
  }
}

.itemHeader {
  margin-block-end: 10px;
}

.description {
  word-break: break-word;

  p:not(:last-child) {
    margin-block-end: 10px;
  }
}

.heading {
  text-decoration: none;

  &:visited {
    color: $ocean;
  }
}
