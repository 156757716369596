@import '@invitae/ids-sass/sass/breakpoints.scss';

.wrapper {
  padding-block: 50px;
}

div.search {
  margin-block-start: 0;

  @include screen-small {
    inline-size: 100%;
  }

  @include screen-medium {
    max-inline-size: 400px;
  }
}

form.searchForm {
  margin-block: 0;
}

.wrapperSearch {
  margin-block: 0 50px;
}

.result {
  margin-block-end: 50px;
}
