@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/layers.scss';

.survivalRateSection {
  position: relative;
  overflow: hidden;
  padding-block-start: 51px;
  padding-block-end: 72px;
  background: $gray7;
  max-inline-size: unset;

  @include screen-medium() {
    padding-block-start: 82px;
    padding-block-end: 93px;
  }

  .headingsContainer {
    grid-column: 1 / -1;
    text-align: center;
    position: relative;
    z-index: $layerPriority;

    @include screen-medium() {
      grid-column: 2 / -2;
    }

    @include screen-large() {
      grid-column: 3 / -3;
    }
  }

  .barsContainer {
    display: flex;
    justify-content: space-between;
    grid-column: 2 / -2;
    flex-direction: column;

    > div {
      @include screen-extra-small-only() {
        inline-size: 120%;
        transform: translateX(-7.5%);
      }

      &:first-child {
        margin-block-start: 20px;
      }

      &:last-child {
        margin-block-start: 52px;
      }
    }

    @include screen-medium() {
      justify-content: center;
      flex-direction: row;

      > div {
        &:first-child {
          margin-block-start: 0;
          margin-inline-end: 40px;
        }

        &:last-child {
          margin-block-start: 0;
        }
      }
    }

    @include screen-large() {
      grid-column: 3 / -3;
    }
  }

  .leftBlob,
  .rightBlob {
    position: absolute;
    z-index: $layerBehind;
    grid-column: unset;
  }

  .leftBlob {
    inset-inline-start: -250px;
    inset-block-start: 138px;
    display: none;

    @include screen-medium() {
      display: initial;
      transform: scale(0.75);
    }

    @include screen-large() {
      transform: initial;
    }
  }

  .rightBlob {
    inset-inline-end: 19px;
    inset-block-end: 133px;

    @include screen-medium() {
      inset-inline-end: 49px;
      inset-block-end: 55px;
    }

    @include screen-large() {
      inset-inline-end: 138px;
      inset-block-end: 111px;
    }
  }

  .button {
    margin-block-start: 48px;
    grid-column: 2/-2;
    inline-size: fit-content;
    justify-self: center;

    @include screen-medium() {
      margin-block-start: 54px;
      grid-column: 6 / -6;
    }
  }
}
