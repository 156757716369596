@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 5px 6px;
  padding-inline: 0;
  margin-block-start: 35px;
  background-color: $white;

  &--anchored {
    box-shadow: 0 -2px 4px -2px rgba(0, 0, 0, 0.5);
  }

  @include screen-small {
    position: sticky;
    inset-block-end: 0;
    margin-block-end: 30px;
  }

  @include screen-medium {
    margin-block-end: 125px;
  }

  // .button is nested in order to increase specificity to overwrite
  // nv-button background-color and font styles
  .button {
    font-size: 16px;
    font-weight: $font-weight-demibold;
    inline-size: 155px;

    background-color: $ocean;

    &:hover {
      background-color: $cobalt;
    }

    &:active {
      background-color: $cyprus;
    }
  }

  .disabled {
    background-color: $gray2;

    &:hover,
    &:focus,
    &:active {
      background-color: $gray2;
    }
  }
}
