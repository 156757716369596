@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';

.publicationItem {
  display: flex;
  margin-block-start: 32px;

  a {
    color: $nero;
  }

  a:visited {
    color: $nero;
  }

  @include screen-medium {
    flex-direction: row;
    justify-content: space-between;
  }

  @include screen-small {
    flex-direction: column;
  }
}

.borderTop {
  margin-block-start: 32px;
  border-block-start: 1px $gray5 solid;
}

.itemLink {
  text-decoration: none;
  margin-block-start: 32px;

  .title:hover {
    color: $ocean;
  }

  .citation {
    margin-block-start: 10px;
  }
}

.errorMessage {
  margin-block-start: 60px;
  color: $ocean;
}
