@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.root {
  color: $white;
  background: $cobalt;
  background-image: url('https://invitae.imgix.net/panel/kitbox.png?w=0.37'),
    url('https://invitae.imgix.net/graphics/blob-bermuda.svg');
  background-repeat: no-repeat;
  background-position:
    calc(50% + 8px) 95%,
    calc(100% + 85px) bottom;
  background-size:
    auto,
    auto 193px;
  padding-block-start: 50px;
  padding-block-end: 219px;

  @include screen-medium {
    background-position:
      85% 97%,
      calc(100% + 85px) bottom;
  }

  @include screen-large {
    padding-block: 100px;
    padding-inline: 0;
    background-image: url('https://invitae.imgix.net/panel/kitbox.png?w=0.5'),
      url('https://invitae.imgix.net/graphics/blob-bermuda.svg');
    background-position:
      85% 88%,
      right bottom;
    background-size: auto, auto;
  }
}

.title {
  font-size: 16px;
  line-height: 31.2px;
  text-align: center;
  text-transform: uppercase;
  font-weight: $font-weight-demibold;

  @include screen-medium {
    letter-spacing: 0.73px;
  }
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  inline-size: 100%;

  p {
    color: $buttermilk;
    text-align: center;
    font-size: 52px;
    font-weight: $font-weight-demibold;
    letter-spacing: 1px;
    line-height: 64px;
    margin-block-start: 10px;

    @include screen-medium {
      letter-spacing: normal;
    }

    @include screen-large {
      font-size: 84px;
      line-height: 90px;
      margin-block-start: 20px;
      letter-spacing: 1px;
    }
  }
}

.description {
  font-size: 22px;
  line-height: 31.2px;
  text-align: center;
  font-weight: $font-weight-demibold;
  letter-spacing: 1px;
  margin-block-start: 10px;

  @include screen-large {
    margin-block-start: 30px;
  }
}
