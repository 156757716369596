@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/headings.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.root {
  background-color: $gray7;
  text-align: center;
  padding-block: 50px;
  padding-inline: 15px;

  @include screen-large {
    padding-block: 100px;
    padding-inline: 15px;
  }

  @include screen-small {
    text-align: start;
  }

  p {
    margin-block-start: 10px;

    &:first-of-type {
      margin-block-start: 50px;
    }
  }

  h4 {
    font-weight: $font-weight-demibold;
    margin-block-start: 50px;
  }
}

.percentages {
  max-inline-size: 920px;
  margin-block: 0;
  margin-inline: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  img {
    margin-block: 25px 0;
    margin-inline: auto;
    flex: 25%;

    @include screen-small {
      flex: 50%;
    }
  }
}
