@import '@invitae/ids-sass/sass/colors.scss';

.dropdownContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown {
  inline-size: 100%;

  &.error {
    border: 1px solid $error;
  }
}
