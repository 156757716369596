@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.root {
  margin-block: 20px;
  margin-inline: 30px;
  display: flex;
  flex-direction: column;

  @include screen-medium {
    margin-block-start: 32px;
  }

  @include screen-large {
    margin-block-start: 120px;
    margin-inline-start: 30px;
  }
}

.sectionHeader {
  font-weight: $font-weight-demibold;
  margin-block-start: 30px;
}

.faqLinksWrapper {
  margin-block-start: 12px;
  margin-block-end: 12px;
}

.link {
  margin-block-end: 24px;
}

.divider {
  @include screen-medium {
    inline-size: 100%;
  }

  background-color: #979797; /* divider color not included in Invitae DSM */
  block-size: 1px;
  margin-block-start: 30px;
}

.iconAndTextWrapper {
  display: flex;
  align-items: center;
  margin-block-start: 10px;
}

.textWithIcon {
  margin-inline-start: 10px;
}

.textWithoutIcon {
  margin-inline-start: 10px;
}

.localPhoneNumbersWrapper {
  display: flex;
  flex-direction: column;
  margin-inline-start: 30px;
}
