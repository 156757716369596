@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/polyfills.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.choice {
  font-size: 16px;
  font-weight: $font-weight-demibold;
  line-height: 24px;

  grid-column: span 2;
  background: $gray7;
  border: solid 1px $gray5;
  border-radius: 8px;
  padding-block: 15px;
  padding-inline: 15px;

  @include ie-only {
    flex: 1 1 40%;
    margin-block-start: 20px;
    margin-inline-start: 20px;
  }

  @include screen-medium {
    font-size: 13px;
    font-weight: $font-weight-medium;
    line-height: normal;
  }

  @include screen-large {
    font-size: 20px;
    font-weight: $font-weight-demibold;
    line-height: 26px;
  }

  &.error {
    border: solid 1px $error;
  }
}

.active {
  background-color: $cobalt;
  border-color: $cobalt;
  color: $white;
}
