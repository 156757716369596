@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/layers.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

/* Older non DSM colors: try to replace with new DSM colors and remove these */
$carbon: #373737;

.root {
  @include screen-small {
    padding-block: 0;
    padding-inline: 0;
  }

  @include screen-medium {
    inline-size: 100%;
    padding-block: 15px;
    padding-inline: 15px;
  }

  @include screen-large {
    padding-block: 30px;
    padding-inline: 30px;
  }
  display: flex;
  position: relative;
  margin-block: 50px 0;
  margin-inline: 0;
  flex-direction: column;
  background: linear-gradient(0deg, $gray6 90%, $white 10%);
}

.backdropFilter {
  filter: blur(6px);
  background-image: url('/svg/invitae-logo-large-background.svg');
}

.form {
  @include screen-small {
    margin-block: 40px 0;
    margin-inline: 0;
    padding-block: 0;
    padding-inline: 0;
    inline-size: 100%;
  }

  @include screen-medium {
    min-inline-size: 453px;
    border-radius: 8px;
    margin-block: 40px 0;
    margin-inline: 0;
    box-shadow: 10px 10px 11px 10px rgba(0, 0, 0, 0.06);
  }

  @include screen-large {
    margin-block: 40px 0;
    margin-inline: 30px 0;
    min-inline-size: 720px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: $carbon;
  min-block-size: 980px;
  padding-block: 30px;
  padding-inline: 30px;
  background-color: $white;
}

.row {
  display: flex;
  justify-content: space-between;
  padding-block: 0;
  padding-inline: 16px;
  inline-size: 100%;
}

.fieldWrapper {
  inline-size: 47%;
}

.label {
  inline-size: 72px;
  block-size: 8px;
  font-size: 12px;
  font-weight: $font-weight-demibold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.67;
  letter-spacing: 0.38px;
}

.field {
  inline-size: 100%;
  block-size: 46px;
  border-radius: 3px;
  border: solid 1px $gray3;
  margin-block-start: 5px;
  font-size: 16px;
  font-weight: $font-weight-book;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  background-color: $white;
  padding-inline-start: 10px;
}

.fullWidthInputField {
  inline-size: 100%;
}

.commentField {
  block-size: 137px;
  padding-block: 10px;
  padding-inline: 10px 0;
}

.captchaAndSubmitbuttonWrapper .button {
  background-color: $mulberry;
  border-block-end: unset;
  margin-block-start: 30px;

  &:hover {
    background-color: $port;
  }
}

.captchaAndSubmitbuttonWrapper {
  align-items: flex-start;
  margin-block-start: 10px;
  display: flex;
  flex-direction: column;
  margin-block-end: 10px;
}

.heading {
  @include screen-small {
    font-size: 22px;
  }
  padding-block: 30px 0;
  padding-inline: 15px;

  @include screen-medium {
    margin-block: 30px 0;
    margin-inline: 15px;
    inline-size: 100%;
  }

  @include screen-large {
    inline-size: 735px;
  }

  @include screen-extra-large {
    margin-inline-start: 8%;
  }
  color: $mulberry;
  z-index: $layerPriority;
}

.background {
  @include screen-medium {
    inset-block-end: 55%;
    inset-inline-start: 10%;
    inset-inline-end: 10%;
  }

  @include screen-large {
    inset-block-end: 85%;
    inset-inline-start: 0%;
    inset-inline-end: 0%;
  }
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 85%;
  background-color: white;
  z-index: $layerBehind;
  transform: rotate(-2.5deg);
}

.formAndContactInfoWrapper {
  flex-direction: column;

  @include screen-large {
    flex-direction: row;
  }

  @include screen-extra-large {
    justify-content: space-around;
  }
  display: flex;
}

.formRoot {
  position: relative;
}

.loadingDnaWrapper {
  z-index: $layerPriority;
  inline-size: 100%;
  inset-block-start: 20%;
  display: flex;
  justify-content: center;
  position: absolute;
}

.requestErroredHeader {
  font-size: 64px;
  line-height: 74px;
  font-weight: $font-weight-demibold;
  color: $mulberry;
}

.successHeader {
  font-size: 64px;
  line-height: 74px;
  color: $deepTeal;
  font-weight: $font-weight-demibold;
}

.errorWrapper {
  @include screen-large {
    inset-block-start: 30%;
  }
  inline-size: 90%;
  inset-block-start: 20%;
  inset-inline-start: 11%;
  position: absolute;
  z-index: $layerPriority;
}

.successWrapper {
  @include screen-large {
    inline-size: 85%;
    inset-block-start: 30%;
    inset-inline-start: 15%;
  }
  inset-inline-start: 13%;
  z-index: $layerPriority;
  inline-size: 80%;
  inset-block-start: 20%;
  position: absolute;
}

.errorBody {
  color: black;
  margin-block-start: 30px;
  font-size: 34px;
  line-height: 42px;
}

.successBody {
  color: black;
  margin-block-start: 30px;
  font-size: 34px;
  line-height: 42px;
}

.singleField {
  justify-content: flex-end;
}

.LoadingDnaSpinnerComponent {
  position: absolute;
}
