@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include screen-extra-small-only {
    padding-block: 0;
    padding-inline: 15px;
  }

  & > * {
    inline-size: 350px;
    margin-block-start: 15px;

    @include screen-extra-small-only {
      inline-size: 100%;
    }

    @include screen-medium() {
      inline-size: 400px;
      margin-block-start: 50px;
    }

    @include screen-large() {
      inline-size: 750px;
      margin-block-start: 100px;
    }
  }
}

.blob {
  inline-size: 100%;
}
