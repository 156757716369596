@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include screen-medium {
    flex-direction: row;
    justify-content: space-between;
  }

  .dropDown {
    justify-content: flex-start;

    @include screen-medium {
      justify-content: flex-end;
    }
  }

  .errorText {
    margin-block-start: 4px;
    color: $error;
  }
}

.label {
  font-size: 16px;
  font-weight: $font-weight-demibold;

  @include screen-medium() {
    margin-inline-end: 15px;
    margin-block-start: 10px;
  }

  @include screen-large {
    font-size: 28px;
  }
}

.errorWrapper {
  flex: 1;
}
