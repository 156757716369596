@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.welcomeTextEntryWrap {
  display: flex;
  align-items: center;
  margin-block-start: 40px;
  flex-direction: column;
  line-height: 24px;
  color: $nero;
}

.multipleChoiceTextEntryWrap {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  line-height: 24px;
  color: $nero;
  margin-block-end: 30px;

  @include screen-medium {
    font-weight: $font-weight-book;
    line-height: normal;
    flex-direction: row;
    margin-block-start: 45px;
  }

  @include screen-large {
    margin-block-start: 70px;
    font-weight: $font-weight-book;
    line-height: 26px;
  }
}

.welcomeTextEntryLabel {
  font-size: 20px;

  @include screen-medium() {
    font-size: 16px;
  }

  @include screen-large() {
    font-size: 32px;
  }
}

.textEntryLabel {
  margin-inline-end: 15px;
  white-space: nowrap;

  @include screen-medium() {
    margin-block-start: 10px;
  }

  @include screen-large() {
    font-size: 28px;
  }
}

.welcomeTextEntryInput {
  max-inline-size: 225px;
  margin-block-start: 40px;

  @include screen-medium {
    max-inline-size: 370px;
  }

  @include screen-large() {
    margin-block-start: 40px;
  }
}

.multipleChoiceTextEntryInput {
  inline-size: 100%;
}

.textEntryLabel,
.welcomeTextEntryLabel {
  font-weight: $font-weight-demibold;
}

.welcomeTextEntryInput,
.multipleChoiceTextEntryInput {
  font-weight: $font-weight-book;
  border: solid 1px $gray3;
  border-radius: 3px;
  padding-block: 10px;
  padding-inline: 10px;
  inline-size: 100%;

  &.error {
    border: solid 1px $error;
  }
}

.errorText {
  margin-block-start: 4px;
  color: $error;
}

.errorWrapper {
  flex: 1;
}
