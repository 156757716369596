@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/transition-durations.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.homeFaq {
  color: $white;
  background-color: $eden;

  .homeFaqWrapper {
    display: flex;
    flex-direction: column;
    max-inline-size: 650px;
    padding-block: 50px;

    hr {
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: $gray4;
    }

    @include screen-large {
      padding-block: 100px;
    }
  }

  .sectionHeading {
    text-align: center;
    margin-block-end: 35px;
  }

  .contentWrapper {
    margin-block-end: 30px;
  }

  .noOutlineOnFocus:focus {
    outline: none;
  }

  .faqRow {
    color: $white;
    padding-block: 20px;
    padding-inline: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    text-align: start;
    cursor: pointer;
    font-weight: $font-weight-book;

    .titleAndText {
      display: flex;
      flex-direction: column;
      inline-size: 100%;

      .text {
        margin-block-start: 0;
        opacity: 0;
        max-block-size: 0;
        overflow: hidden;
        transition-delay: 0s;
        transition-timing-function: ease-out;
        transition-duration: $transition-duration-entering;
        transition-property: opacity, max-height, margin-top;
      }

      .showText {
        max-block-size: 500px;
        opacity: 1;
        transition-delay: 0s;
        transition-timing-function: ease-in-out;
        transition-duration: $transition-duration-entering;
        transition-property: opacity, max-height, margin-top;
        margin-block-start: 20px;
      }
    }

    .caret {
      margin-block-start: 7px;
      margin-inline-end: 20px;
      transform: scale(0.75);
      transition: transform $transition-duration-entering ease-out;

      svg {
        fill: $gray4;
      }
    }

    .caretUp {
      transform: scale(0.75) rotate(180deg);
      transition: transform $transition-duration-entering linear;
    }
  }

  .seeAllFaqs {
    color: $buttermilk;
    text-decoration: underline;
    margin-block-end: 30px;
    font-weight: $font-weight-book;
  }

  .sectionFooter {
    padding-block-end: 50px;
    padding-inline: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screen-large {
      padding-block-end: 100px;
      padding-inline: 0;
    }

    @include screen-extra-large {
      background-position:
        -100px 100px,
        right 100px;
    }

    .subheading + :global(.nv-button) {
      margin-block-start: 40px;
    }
  }
}
