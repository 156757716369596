@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/polyfills.scss';

.textEntryForm {
  margin-block-start: 25px;
}

.textEntryForm > *:not(:first-child) {
  margin-block-start: 30px;
}

.multipleOptionsSingleAnswer {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  margin-block-start: 15px;

  @include ie-only {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include screen-medium {
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    margin-block-start: 30px;
    inline-size: 510px;

    // Needed because this block overflows the parent container
    // and we need to center around it.
    margin-inline-start: 50%;
    transform: translateX(-50%);
  }

  @include screen-large {
    inline-size: 990px;
  }
}

.errorText {
  color: $error;
  text-align: center;
  margin-block-start: 15px;
}
