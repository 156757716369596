@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.webinarNavigation {
  margin-block-start: 32px;
  padding-block-start: 32px;
  border-block-start: 1px solid $gray4;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  @include screen-large() {
    justify-content: space-between;
    flex-direction: row;
  }
}

.webinarFilters {
  display: flex;
  align-items: flex-end;
  inline-size: 100%;
  flex-direction: column;

  @include screen-large() {
    margin-block-start: 0;
    inline-size: inherit;
    flex-direction: row;
  }

  > div {
    margin-block-start: 16px;
    inline-size: 100%;

    @include screen-large() {
      inline-size: 200px;
      margin-inline-end: 16px;
    }
  }
}

.webinarBrowse {
  inline-size: 100%;

  @include screen-large() {
    inline-size: 30%;
  }
}

.submitButton {
  block-size: 46px;
  inline-size: 100%;
  margin-block-start: 24px;

  @include screen-large() {
    inline-size: initial;
  }
}
