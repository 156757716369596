@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/polyfills.scss';
@import '@invitae/ids-sass/sass/layers.scss';

.textContent {
  grid-column: 12 span;
}

.mediaContent {
  grid-column: 12 span;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  // * * *
  // a way to make spacing dynamic with position absolute
  // to get this value, we increase the percentage until the image occupies the entire width
  // * * *
  padding-block-end: 67%;

  > img {
    position: absolute;
    block-size: 100%;
    inset: 0;
    margin: auto;
    transition: opacity 1.5s linear;
    opacity: 0;

    &.show {
      opacity: 1;
    }
  }
}

@include screen-medium {
  .textContent {
    grid-column: 1 / 6;
  }

  .mediaContent {
    grid-column: 6 / 13;

    picture {
      inline-size: 100%;
      block-size: 100%;

      img {
        object-fit: cover;
      }
    }
  }
}

.root {
  row-gap: 30px;
  padding-block: 55px;
  padding-inline: 0;

  img {
    border-radius: 5px;
    inline-size: 100%;
  }

  @include screen-large {
    padding-block: 60px;
    padding-inline: 0;
  }
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading + .body {
  margin-block-start: 20px;
}

.actions {
  margin-block-start: 20px;

  :global(.nv-button) {
    margin-block-start: 25px;
  }

  :global(.nv-button):first-child {
    margin-inline-end: 25px;
  }
}
