@import '@invitae/ids-sass/sass/font-weights.scss';
@import '@invitae/ids-sass/sass/breakpoints.scss';

.value {
  display: flex;
  margin-inline-start: 15px;
}

.number {
  font-weight: $font-weight-demibold;
  font-variant-numeric: proportional-nums;
  font-size: 50px;
  line-height: 60px;

  @include screen-medium() {
    font-size: 77px;
    line-height: 90px;
  }

  @media (max-width: 610px) {
    font-size: 40px;
    line-height: 40px;
  }
}

.percentage {
  margin-block-start: 7px;

  @media (max-width: 610px) {
    margin-block-start: 0;
  }
}

.label {
  @media (max-width: 610px) {
    // !important needed as CircularProgressbarWithChildren package/component scales
    // further than our NvHeading/NvSubheading fonts on small screens
    // Not only is this a custom component, it's also an edge case so
    // IMO !important usage and custom @media css rule are justified for this case
    font-size: 14px !important; /* stylelint-disable-line declaration-no-important */
    line-height: 20px !important; /* stylelint-disable-line declaration-no-important */
    max-inline-size: 150px;
  }

  margin-block-start: 11px;
  max-inline-size: 190px;
  text-align: center;
}
