@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/polyfills.scss';

.webinarItem {
  display: flex;
  flex-direction: column;
  padding-block-start: 32px;

  .bottomButton {
    margin-block-start: 20px;
    display: unset;

    @include screen-medium {
      display: none;
    }
  }

  .sideButton {
    margin-inline-start: 40px;
    margin-block: 16px;
    display: none;

    @include screen-medium {
      display: unset;
    }
  }
}

.titleAndButtonTopRow {
  display: flex;

  @include screen-medium {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.itemInfoText {
  margin-block-end: 10px;
  color: $nero;
}

.titleAndPresenters {
  text-decoration: none;

  h3 {
    margin-block-end: 5px;
    color: $ocean;

    &:hover {
      text-decoration: underline;
      color: $ocean;
    }
  }
}

.titleAndPresentersInStandalonePage {
  h3 {
    margin-block-end: 5px;
  }
}

.borderTop {
  margin-block-start: 32px;
  border-block-start: 1px $gray5 solid;
}

.webinarButton {
  align-self: start;

  @include ie-only {
    block-size: 50px;
  }
}
