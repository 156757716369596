@import '@invitae/ids-sass/sass/breakpoints.scss';

.container {
  margin-block-end: 32px;
  display: flex;
  flex-direction: column;
}

.goBack {
  margin-block-start: 35px;
}
