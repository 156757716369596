@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/polyfills.scss';

.homeJourneysFooterSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  background-image: url('https://invitae.imgix.net/graphics/blob-yellow-testcatalog.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;

  .healthcareProviderText {
    margin-block-end: 30px;
    text-align: center;
    max-inline-size: 345px;

    a {
      color: $ocean;
      text-decoration: underline;
    }

    @include screen-medium {
      max-inline-size: unset;
    }

    @include screen-large {
      margin-block-end: 60px;
    }
  }

  .footerButton {
    margin-block-end: 50px;

    @include screen-large {
      margin-block-end: 100px;
    }
  }

  @include screen-large {
    margin-block-start: -60px;
  }
}

.cardNarrowGrid {
  @include screen-large {
    grid-template-columns: repeat(4, 1fr);
  }
}
