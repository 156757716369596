@import '@invitae/ids-sass/sass/breakpoints.scss';
@import '@invitae/ids-sass/sass/colors.scss';
@import '@invitae/ids-sass/sass/transition-durations.scss';
@import '@invitae/ids-sass/sass/font-weights.scss';

.root {
  counter-reset: referenceCounter;
  display: flex;
  flex-direction: column;
}

// extends QuizHeader.module.scss .container
.container-header {
  text-align: center;

  @include screen-medium {
    min-block-size: 180px;
  }

  @include screen-large {
    min-block-size: 390px;
  }
}

// extends Container.module.scss .root
.container-body {
  background: $pearl;
}

.callout {
  border-radius: 8px;
  background: $white;
  font-weight: $font-weight-demibold;
  padding-block: 15px;
  padding-inline: 15px;
  color: $cooledCarbon;

  @include screen-medium() {
    padding-block: 15px;
    padding-inline: 35px;
  }
}

.lead-in {
  text-align: center;
  font-weight: $font-weight-demibold;
}

.reference {
  font-size: 14px;
}

.footnotes {
  font-size: 14px;
}

.reference::before {
  counter-increment: referenceCounter;
  content: counter(referenceCounter) '. ';
}

.image-container {
  min-inline-size: 80px;
  min-block-size: 80px;
  display: grid;
  place-content: center;
}

.card {
  align-self: center;
  padding-block: 10px;
  padding-inline: 10px;
  font-weight: $font-weight-demibold;
  color: $nero;
  box-shadow: 0 0 20px 0 rgba(40, 41, 44, 0.12);
  border-radius: 8px;
  background: $white;
  min-block-size: 100px;
  inline-size: 310px;
  max-inline-size: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  inset-block-start: 0;
  transition: top ease $transition-duration-entering;
}

// Apply some margin only if the card would otherwise touch the bottom edge.
.card:last-child {
  margin-block-end: 50px;

  @include screen-medium() {
    margin-block-end: 75px;
  }

  @include screen-large() {
    margin-block-end: 150px;
  }
}

.card:hover {
  @include screen-medium() {
    inset-block-start: -10px;
  }
}

.body + .body {
  margin-block-start: 20px;
}

.body + .callout,
.callout + .body {
  margin-block-start: 25px;

  @include screen-medium() {
    margin-block-start: 30px;
  }
}

.body + .lead-in,
.callout + .lead-in {
  margin-block-start: 30px;

  @include screen-medium() {
    margin-block-start: 40px;
  }

  @include screen-medium() {
    margin-block-start: 60px;
  }
}

.lead-in + .card {
  margin-block-start: 30px;
}

.card + .card {
  margin-block-start: 30px;
}

.card + .reference,
.card + .footnotes {
  margin-block-start: 100px;
}
